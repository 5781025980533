(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.common.components.disquscomments.viewer.skins.DisqusCommentsSkin'] = {
  "react": [
    [
      "iframe",
      "disqusCommentsHolder",
      [],
      {
        "frameBorder": 0,
        "scrolling": "no",
        "allowtransparency": "true"
      }
    ],
    [
      "div",
      "disqusCommentsPreviewOverlay",
      [],
      {}
    ],
    [
      "div",
      "noDisqusIdMessage",
      [],
      {}
    ]
  ],
  "css": {
    "%": "position:relative;min-height:407px;",
    "%disqusCommentsHolder": "width:100%;",
    "%disqusCommentsPreviewOverlay": "position:absolute;top:0;bottom:0;left:0;right:0;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;",
    "%noDisqusIdMessage": "font-family:\"Helvetica Neue\", \"HelveticaNeueW01-45Roma\", \"HelveticaNeueW02-45Roma\", \"HelveticaNeueW10-45Roma\", Helvetica, Arial, sans-serif;position:absolute;top:0;bottom:0;left:0;right:0;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;background-color:rgba(255, 255, 255, 0.8);font-size:15px;text-align:center;color:#2b5672;"
  }
}

        return skins;
    }));