define(['santa-components', 'lodash', 'coreUtils', 'skins', 'disqusComments/skins/skins.json', 'componentsCore'], function (santaComponents, _, coreUtils, skinsPackage, skinsJson, componentsCore) {
    'use strict';

    const urlUtils = coreUtils.urlUtils,
        DEFAULT_DISQUS_ID = 'wixdemo123';


    /**
     * @class components.disqusComments
     * @extends {core.skinBasedComp}
     * @extends {core.postMessageCompMixin}
     * @extends {componentsCore.mixins.uniquePageIdMixin}
     */
    const disqusComments = {
        displayName: 'DisqusComments',
        propTypes: {
            compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
            id: santaComponents.santaTypesDefinitions.Component.id.isRequired,
            languageCode: santaComponents.santaTypesDefinitions.RendererModel.languageCode.isRequired,
            santaBase: santaComponents.santaTypesDefinitions.santaBase,
            rootPageTitle: santaComponents.santaTypesDefinitions.rootPageTitle,
            currentUrl: santaComponents.santaTypesDefinitions.currentUrl.isRequired,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen.isRequired
        },
        mixins: [
            componentsCore.mixins.skinBasedComp,
            componentsCore.mixins.postMessageCompMixin,
            componentsCore.mixins.uniquePageIdMixin
        ],

        /**
         * Uses 'editor' constant for editor or preview
         * Uses mixins.pageMetaInfoMixin.getUniquePageId for all other cases
         *
         * The main reason, why we use hashing here is because disqus comments seem to have problems with long disqus
         * identifiers
         *
         * @returns {string} SHA 256 hash
         */
        getDisqusInstanceId() {
            return this.getDisqusId && this.getDisqusId() || // eslint-disable-line no-mixed-operators
                this.getUniquePageId();
        },

        getIframeSrc() {
            const disqusIdentifier = this.getDisqusInstanceId(),
                iframeParams = {
                    'disqusId': this.props.compData.disqusId ? this.props.compData.disqusId : DEFAULT_DISQUS_ID,
                    'pageUrl': _.isString(this.props.currentUrl) ? this.props.currentUrl : this.props.currentUrl.full,
                    'pageTitle': this.props.rootPageTitle,
                    'compId': this.props.id,
                    'disqusInstanceId': disqusIdentifier
                };

            return `${this.props.santaBase}/static/external/disqusComments.html?${urlUtils.toQueryString(iframeParams)}`;
        },

        getSkinProperties() {
            const hasDisqusId = !!this.props.compData.disqusId;

            return {
                '': {
                    style: {
                        height: this.state.$disqusCommentsDesiredHeight ? this.state.$disqusCommentsDesiredHeight : ''
                    }
                },
                'disqusCommentsHolder': {
                    'data-src': this.getIframeSrc(),
                    key: this.getDisqusInstanceId(),
                    style: {
                        height: this.state.$disqusCommentsDesiredHeight ? this.state.$disqusCommentsDesiredHeight : ''
                    }
                },
                'disqusCommentsPreviewOverlay': {
                    style: {
                        display: 'none'
                    }
                },
                'noDisqusIdMessage': {
                    style: {
                        display: !hasDisqusId ? '' : 'none'
                    },
                    children: this.getTranslation('disqusComments_notDisqusIdMessage')
                }
            };
        },

        componentWillReceiveProps(nextProps) {
            this.setState({
                $disqusId: nextProps.compData.disqusId
            });
        },

        getTranslation(key) {
            return this.translatedKeys[key] || key;
        },

        getInitialState() {
            this.translatedKeys = coreUtils.translationsLoader.getTranslationAllKeys('disqusComments', this.props.languageCode, {});
            return {
                '$disqusId': this.props.compData.disqusId,
                '$disqusCommentsDesiredHeight': 200
            };
        },

        onDisqusCommentsHolderMsg(data) {
            this.prevHeight = this.state.$disqusCommentsDesiredHeight;

            this.setState({
                $disqusCommentsDesiredHeight: data.height
            });

            if (this.prevHeight !== data.height) {
                this.registerReLayout();
            }
        },

        componentDidMount() {
            this.setPostMessageHandler(this.props.id, this.onDisqusCommentsHolderMsg);
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.common.components.disquscomments.viewer.DisqusComments', disqusComments);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return disqusComments;
});
